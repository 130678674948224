'use strict';
import { gsap } from "gsap";
    
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

// animation navigation
// sticky nav
let lastScrollValue = 0;
const nav = document.querySelector('.nav');

window.document.addEventListener('scroll', scrollListener);

function scrollListener(){
  if(lastScrollValue > window.scrollY){
    gsap.to('.nav', {transform: 'translateY(0)', duration: 0.3,});
  }else{
    gsap.to('.nav', {transform: 'translateY(-108px)', duration: 0.3,});
  }
  lastScrollValue = window.scrollY;
};

// nav button 
const navBtn = document.querySelector('.nav__btn');

navBtn.addEventListener('click', () => {
    nav.classList.toggle('nav--active');

    menuActive();
});

// disparition du nav menu lorsque on clique sur le logo
const navLogo = document.querySelector('.nav__logo');

navLogo.addEventListener('click', () => {

  if(nav.classList.contains('nav--active')){
    nav.classList.remove('nav--active');

    gsap.to('.nav__menu', {autoAlpha: 0, duration: 0.1, ease: 'power2.inOut'});
  }

});

// animation menu active
function menuActive(){
    if(nav.classList.contains('nav--active')){
        gsap.to('.nav__menu', {autoAlpha: 1, duration: 0.5, ease: 'power2.inOut'});
    }else{
        gsap.to('.nav__menu', {autoAlpha: 0, duration: 0.5, ease: 'power2.inOut'});
    };
};

// disparition du menu au click d'un lien
const links = document.querySelectorAll('.links__el');

links.forEach((link) => {
  link.addEventListener('click', () => {
    gsap.to('.nav__menu', {autoAlpha: 0, duration: 0.1, ease: 'power2.inOut'});

    gsap.to('.nav', {transform: 'translateY(-108px)', duration: 0.3, delay: 1});

    nav.classList.remove('nav--active');
  });
});

const body = document.querySelector('body');

// animation de la page landing
if(body.classList.contains('landing')){

  // animation du background de la nav en fonction du header
  gsap.to('.nav', {
    backgroundColor : '#1e6471',
    scrollTrigger : {
      trigger : 'header',
      start : 'bottom +=112',
      endTrigger : 'header',
      end : 'bottom 0%',
      scrub : true,
    }
  });

  //animation header
  // typewriter
  const words = ["GRAPHISME", "WEB", "MUSIQUE", "ÉCOLOGIQUE"]; // Mots à afficher
  const typewriterElement = document.querySelector('.typewriter');
  let wordIndex = 0;
  let charIndex = 0;
  let isDeleting = false;
  let speed = 150;

  function typeEffect() {
    const currentWord = words[wordIndex];
    if (isDeleting) {
      charIndex--; // Efface une lettre
    } else {
      charIndex++; // Ajoute une lettre
    }

    // Mise à jour du contenu
    typewriterElement.textContent = currentWord.substring(0, charIndex);

    // Si le mot est terminé
    if (!isDeleting && charIndex === currentWord.length) {
      isDeleting = true; // Commence à effacer
      speed = 1500; // Petite pause avant d'effacer
    } else if (isDeleting && charIndex === 0) {
      isDeleting = false; // Passe au mot suivant
      wordIndex = (wordIndex + 1) % words.length; // Boucle sur les mots
      speed = 500; // Pause avant de commencer à écrire le prochain mot
    } else {
      speed = isDeleting ? 50 : 150; // Vitesse variable pour effacer ou écrire
    }

    setTimeout(typeEffect, speed);
  }

  // Lancer l'effet de typewriter
  typeEffect();

  // animation main
  // section elasticity
  // apparition du text
  const elasticityParagraphes = document.querySelectorAll('div.elasticity__content > p');
  
  elasticityParagraphes.forEach((elasticityParagraphe) => {

    gsap.from(elasticityParagraphe, {
      translateX: '-100%', 
      opacity: 0,
      ease: 'power1.inOut',
      duration: 1.0,
      scrollTrigger : {
        trigger : '.elasticity',
        start : 'center center',
      }
    });

  });

  // section eco
  // apparition du text
  gsap.from('.eco__content', {
    translateX: '-100%', 
    opacity: 0,
    ease: 'power1.inOut',
    duration: 1.0,
    scrollTrigger : {
      trigger : '.eco',
      start : 'center center',
    }
  });

  // parallax de l'image
  gsap.fromTo('.eco__asset img', 
    {
      scale : 1,
    },
    {
      scale : 0.4,
      scrollTrigger : {
        trigger : '.eco__asset',
        start : 'top bottom',
        end : 'center 50%',
        scrub : true,
      }
  });

  // section services
  // apparition des list__el
  gsap.from('.services__list .list__el:nth-child(1)', {
    translateX: '-100%', 
    opacity: 0,
    ease: 'power1.inOut',
    duration: 1.0,
    scrollTrigger : {
      trigger : '.services__list .list__el:nth-child(1)',
      start : 'top 75%',
    }
  });

  gsap.from('.services__list .list__el:nth-child(2)', {
    translateX: '100%', 
    opacity: 0,
    ease: 'power1.inOut',
    duration: 1.0,
    scrollTrigger : {
      trigger : '.services__list .list__el:nth-child(2)',
      start : 'top 75%',
    }
  });

  gsap.from('.services__list .list__el:nth-child(3)', {
    translateX: '-100%', 
    opacity: 0,
    ease: 'power1.inOut',
    duration: 1,
    scrollTrigger : {
      trigger : '.services__list .list__el:nth-child(3)',
      start : 'top 75%',
    }
  });

  // section methode
  // apparition des bars au scroll
  gsap.to('.methode__bar_1', {
    height : '152px',
    scrollTrigger : {
      trigger : '.methode__step:nth-child(1) .step__asset',
      start : 'bottom 75%',
      endTrigger : '.methode__step:nth-child(2) .step__asset',
      end : 'top 75%',
      toggleActions : 'play pause pause pause',
      scrub : 0.5,
    }
  });

  gsap.to('.methode__bar_2', {
      height : '152px',
      scrollTrigger : {
        trigger : '.methode__step:nth-child(2) .step__asset',
        start : 'bottom 75%',
        endTrigger : '.methode__step:nth-child(3) .step__asset',
        end : 'top 75%',
        scrub : 0.5,
      }
  });

  // apparition des steps au scroll
  gsap.to('.methode__step:nth-child(2)', {
    opacity : 1,
    duration : 0.3,
    scrollTrigger : {
      trigger : '.methode__step:nth-child(2) .step__asset',
      start : 'top 75%',
    }
  });

  gsap.to('.methode__step:nth-child(3)', {
    opacity : 1,
    duration : 0.3,
    scrollTrigger : {
      trigger : '.methode__step:nth-child(3) .step__asset',
      start : 'top 75%',
    }
  });

  // section tarifs
  // apparition des cards
  const tarifsCards = document.querySelectorAll('.card__el');
  
  tarifsCards.forEach((tarifsCard) => {

    gsap.from(tarifsCard, {
      scale: 0, 
      ease: 'power1.inOut',
      duration: 0.7,
      scrollTrigger : {
        trigger : tarifsCard,
        start : 'top 90%',
      }
    });

  });

  // animation du portfolio
  const portfolioProjets = document.querySelectorAll('.gallery__el');
    
  portfolioProjets.forEach((portfolioProjet) => {

    gsap.from(portfolioProjet, {
      scale: 0, 
      ease: 'power1.inOut',
      duration: 0.7,
      scrollTrigger : {
        trigger : portfolioProjet,
        start : 'top 90%',
      }
    });

  });

};

// animation de la page portfolio
if(body.classList.contains('portfolio_main')){

  // background couleur de la nav
  gsap.to('.nav', {backgroundColor : '#1e6471', duration: 0});

  // section portfolio
  // tri des projets
  const portfolioArray = document.querySelectorAll('.gallery__el');

  // apparition de tous les éléments du tableau
  const triBtnTout = document.querySelector('.portfolio__btn__tout');

  triBtnTout.addEventListener('click', () => {

    portfolioArray.forEach((portfolioEl) => {

      gsap.to(portfolioEl, {display: "block", duration: 0});

    });

  });

  // tri selon la class affiche
  const triBtnAffiche = document.querySelector('.portfolio__btn__affiche');

  triBtnAffiche.addEventListener('click', () => {

    portfolioArray.forEach((portfolioEl) => {

      if (portfolioEl.classList.contains('affiche')) {

          gsap.to(portfolioEl, {display: "block", duration: 0});

      }else{

        gsap.to(portfolioEl, {display: "none", duration: 0});

      }

    });

  });

  // tri selon la class branding
  const triBtnBranding = document.querySelector('.portfolio__btn__branding');

  triBtnBranding.addEventListener('click', () => {

    portfolioArray.forEach((portfolioEl) => {

      if (portfolioEl.classList.contains('branding')) {

          gsap.to(portfolioEl, {display: "block", duration: 0});

      }else{

        gsap.to(portfolioEl, {display: "none", duration: 0});

      }

    });

  });

  // tri selon la class site_web
  const triBtnWeb = document.querySelector('.portfolio__btn__site_web');

  triBtnWeb.addEventListener('click', () => {

    portfolioArray.forEach((portfolioEl) => {

      if (portfolioEl.classList.contains('site_web')) {

          gsap.to(portfolioEl, {display: "block", duration: 0});

      }else{

        gsap.to(portfolioEl, {display: "none", duration: 0});

      }

    });

  });

};

// apparition de l'image d'un projet au clic
const portfolioArray = document.querySelectorAll('.gallery__el');

portfolioArray.forEach((portfolioEl) => {

  portfolioEl.addEventListener('click', () => {

    if (portfolioEl.classList.contains('clicked')) {
  
      portfolioEl.classList.remove('clicked');
  
    }else{
  
      portfolioEl.classList.add('clicked');
  
    }

  })

});

